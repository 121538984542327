import axios from './shein-axios/index'
import cmcClientService from '@shein-aidc/basis-end-config'
import {
  fixBffChallengeContent,
  cookieFix,
  commonHeader,
  apiSignatureRequest,
  apiSignatureResponse,
  signatureFeedBack,
  asyncCheckLogin,
  forceLogin,
  circuitBreakerTip,
  apiLog,
  traceMonitor,
  ipv6,
  dataAggregation,
  addXsrfTokenInterceptor
} from './interceptors'
import { setLocalStorage } from '@shein/common-function'
import { setPersonalizedHeader } from '@/common/personalizedFrontUtil'

const schttp = (() => {
    // 谨防 SSR 改写 node axios
    if (typeof window === 'undefined') return axios
    const config = {
      baseURL: gbCommonInfo.langPath,
      headers: {
        common: {
          'x-csrf-token': gbCommonInfo.csrf_token,
          'x-requested-with': 'XMLHttpRequest'
        }
      },
      params: {
        _ver: '1.1.8',
        _lang: gbCommonInfo.appLanguage
      }
    }
    if (gbCommonInfo.csrf_token) {
      config.headers.common['x-csrf-token'] =  gbCommonInfo.csrf_token
    }
    const instance = axios.create(config)

    instance.updateXCsrfToken = async (csrfToken) => {
      if (!csrfToken) {
        const response = await instance({ url: '/api/common/csrf/update', ignoreInterceptor: ['csrf'] }).catch(()=>({}))
        if (!response.updateCs) return false
        csrfToken = response.updateCs
      }
      // eslint-disable-next-line require-atomic-updates
      instance.defaults.headers.common['x-csrf-token'] = csrfToken
      gbCommonInfo.csrf_token = csrfToken
      return csrfToken
    }


    // ========== set your interceptors here ==========

    instance.interceptors.request.use(cookieFix)
    instance.interceptors.request.use(addXsrfTokenInterceptor)
    instance.interceptors.request.use(apiSignatureRequest)
    instance.interceptors.request.use(signatureFeedBack.hold)
    instance.interceptors.request.use(traceMonitor.startRootSpan)
    instance.interceptors.request.use(commonHeader)
    instance.interceptors.request.use(dataAggregation)
    // personalized switch
    instance.interceptors.request.use(setPersonalizedHeader)
    // -- request   ↑
    // -- response  ↓
    instance.interceptors.response.use(fixBffChallengeContent)
    instance.interceptors.response.use(apiLog.success, apiLog.error)
    instance.interceptors.response.use(apiSignatureResponse)
    instance.interceptors.response.use(signatureFeedBack.replay(instance))
    instance.interceptors.response.use(asyncCheckLogin(instance))
    instance.interceptors.response.use(forceLogin)
    instance.interceptors.response.use(circuitBreakerTip)
    instance.interceptors.response.use(traceMonitor.success, traceMonitor.error)
    instance.interceptors.response.use(ipv6)
    instance.interceptors.response.use((response) => {
      // 端配客户端拦截
      cmcClientService.httpIntercept(response.headers)
      return response
    })
    // ...
    // ========== END ==========
    instance.interceptors.response.use(
      response => response.config.schttp?.getResponse || response.config?.__fromBSLibs__ ? response : response.data,
      response => { throw response.config?.schttp?.getResponse || !response.request ? response : response.request }
    )

    window.schttp = instance
    return instance
})()

/** 
 * ========== best practice ==========
 * import schttp from 'public/src/services/schttp'
 * 只使用对象形式请求, 以便未来技术替换
 * https://axios-http.com/zh/docs/req_config
 * 
 * schttp({
 *   // method: 'GET', // by default
 *   url: '/somePath/someApi',
 *   params: { foo: 'bar' }
 * })
 * 
 * schttp({
 *   method: 'POST',
 *   url: '/somePath/someApi',
 *   data: { foo: 'bar' }
 * })
 */

// test
export default schttp

export function getSampleControlConfig() {
  schttp({
    url: '/api/config/sampleControlConfig/get',
    method: 'post',
  }).then(({ code, info }) => {
    if (Number(code) === 0) {
      setLocalStorage({
        key: 'sky_eye_sample_control_config',
        value: info.skyEyeConfig || {},
        // 1h 过期
        expire: 1000 * 60 * 60
      })
    }
  })
}
